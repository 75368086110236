import { Typography, Table, Divider, Row, Col, Collapse, Tooltip, TablePaginationConfig } from 'antd';
import ComputerIcon from '@material-ui/icons/Computer';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FlipCameraAndroidOutlinedIcon from '@material-ui/icons/FlipCameraAndroidOutlined';
import Button from '@material-ui/core/Button';
import styles from "./Edge.module.scss";
import { IGateway, ViewType } from '../ResourceManagement/ResourceManagement';
import ResourceTable from '../../components/ResourceTable/ResourceTable';
import { APIS } from '../../utils';
import { useState } from 'react';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

type Props = {
    gateway?: IGateway;
    onChangeViewType: any;
    apis?: any;
}

const chartColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

const chartDeploymentColumns: any = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        render: (text: string, record: any) => (
            record.message && <Tooltip placement="topLeft" title={record.message}>
                <InfoOutlinedIcon />
            </Tooltip>
        ),
    }
];

const k8sDeploymentColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Namespace',
        dataIndex: 'namespace',
        key: 'namespace',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

export default function Edge(props: Props) {
    const [tableState, setTableState] = useState({});
    const [tableSelection, setTableSelection] = useState({
        selectedRowKeys: [],
        selectedRows: []
    });

    function onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        setTableState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    }

    function getDefaultActiveKey(): string {
        if (props?.gateway?.edge?.filters) {
            return props?.gateway?.edge?.filters[0]?.name
        } else {
            return '';
        }
    }

    return (
        <div className={styles.wrapper}>
            <Row>
                <Col span={3}>
                    <Button
                        variant="outlined"
                        color='primary'
                        startIcon={<FlipCameraAndroidOutlinedIcon />}
                        onClick={() => props.onChangeViewType(ViewType.VISUAL)}
                        style={{ "width": "100%" }}
                    >
                        Show overview
                    </Button>
                </Col>
                <Col span={5}>
                </Col>
                <Col span={8}>
                    <Title level={2} style={{
                        marginBottom: '0px',
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            textAlign: 'center',
                            justifyContent: 'center',
                        }}>
                            <ComputerIcon style={{ marginRight: '0.5rem' }} />
                            Gateway
                        </div>
                    </Title>

                    <Title level={4} style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginTop: '0px',
                    }}>
                        {props.gateway?.name}
                    </Title>
                </Col>
            </Row>
            <Divider orientation="left"><Title level={5}>Gateway Properties</Title></Divider>

            <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Namespace</Title>
                    <Text>{props.gateway?.namespace}</Text>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Region</Title>
                    <Text>{props.gateway?.labels?.region}</Text>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Class</Title>
                    <Text>{props.gateway?.class}</Text>
                </Col>
            </Row>

            {props.gateway?.edge ? <div>
                <Divider orientation="left"><Title level={5}>Edge Properties</Title></Divider>
                <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                        <Title level={5}>Name</Title>
                        <Text>{props.gateway?.edge?.name}</Text>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Title level={5}>Namespace</Title>
                        <Text>{props.gateway?.edge?.namespace}</Text>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <Title level={5}>Kubernetes Context</Title>
                        <Text>{(props.gateway?.edge && props.gateway?.edge['current-context'])}</Text>
                    </Col>
                </Row>

                <Title level={5} style={{ marginTop: '2rem' }}>
                    Chart Deployment Status
                </Title>
                <Table size="small" columns={chartDeploymentColumns} dataSource={props.gateway?.edge?.conditions?.map((condition) => {
                    return {
                        key: `${condition.lastTransitionTime}-${condition.type}`,
                        time: `${new Date(condition.lastTransitionTime)}`,
                        reason: condition.reason,
                        type: condition.type,
                        message: condition.message,
                    }
                })} />

                <Title level={5}>
                    Charts
                </Title>
                <Table size="small" columns={chartColumns} dataSource={props.gateway?.edge?.charts?.map((chart) => {
                    return {
                        key: `${chart.namespace}-${chart.name}`,
                        name: chart.name,
                        version: chart.version,
                        status: chart.releaseStatusDescription,
                    }
                })} />

                <Divider orientation="left">
                    <Title level={5} style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        textAlign: 'center',
                    }}>
                        <div >
                            Kubernetes Deployments
                        </div>
                    </Title>
                </Divider>

                <Table size="small" columns={k8sDeploymentColumns} dataSource={props.gateway?.edge?.deployments?.map((deployment) => {
                    return {
                        key: `${deployment.namespace}-${deployment.name}`,
                        name: deployment.name,
                        namespace: deployment.namespace,
                        status: deployment.status,
                    }
                })} />

                <Divider orientation="left"><Title level={5}>Filters</Title></Divider>
                {(!props.gateway?.edge?.filters) &&
                    <Text>No filters</Text>
                }

                <Collapse defaultActiveKey={[getDefaultActiveKey()]}>
                    {props.gateway?.edge?.filters?.map((filter) => {
                        const value = JSON.stringify(JSON.parse(filter.value), null, 2)
                        return <Panel key={filter.name} header={filter.name}>
                            <Paragraph>
                                <pre>
                                    <Text code>{value}</Text>
                                </pre>
                            </Paragraph>
                        </Panel>
                    })}
                </Collapse>

                <Divider orientation="left"><Title level={5}>APIs</Title></Divider>
                <Text>
                    Showing only APIs for gateway {props.gateway?.name}
                </Text>
                <ResourceTable
                    listName={APIS}
                    list={props.apis}
                    currentView={APIS}
                    handleResourceListChange={() => { }}
                    tableState={tableState}
                    tableSelection={tableSelection}
                    onRowSelectionChange={() => { }}
                    onTableChange={onTableChange}
                    refreshTable={() => { }}
                    readonly={true}
                />

            </div> :
                <div></div>
            }
        </div>
    );
}
