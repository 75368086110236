import config from '../config';
import axios from 'axios';

let accessToken: string;
let userName: string;
let userId: string;

export const getAccessToken = () => {
    return accessToken;
};

export const getUserName = () => {
    return userName;
};

export const authenticate = async (): Promise<boolean | undefined> => {
    try {
        const response = await axios.get(`${config.api.invokeUrl}/auth/whoami`, {
            withCredentials: true,
        });
        if (response.status === 200) {
            const user = response.data;
            accessToken = user.token;
            userName = user.name;
            userId = user.id;
            return true;
        } else {
            console.error(response.status);
            window.location.assign(`${config.api.invokeUrl}/auth/callback`);
        }
    } catch (e: any) {
        if (e.isAxiosError) {
            if (e.response) {
                console.error(e.response.data);
                if (e.response.status >= 400) {
                    window.location.assign(`${config.api.invokeUrl}/auth/callback`);
                }
            } else {
                console.error(e);
            }
        } else {
            console.error(e);
        }
    }
}