import { Layout, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import Map from '../Map/Map';
import styles from "./Overview.module.scss";
import { useState, useEffect } from 'react';

import { IGateway } from '../ResourceManagement/ResourceManagement';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ComputerIcon from '@material-ui/icons/Computer';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import DomainIcon from "@material-ui/icons/Domain";

const { Content } = Layout;

interface OverviewProps {
    data: any;
    gateways: IGateway[],
    showGateway: any,
    showLandscapeDetails: any,
}

function Overview(props: OverviewProps) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(props.gateways?.length === 0);
    }, [props.gateways?.length]);

    return (
        <>
            <Layout style={{ paddingTop: '0.75rem' }}>
                <Row style={{height: "100%"}}>
                    <Col span={4}>
                        <Content>
                            <Card className={styles.card} style={{ display: isLoading ? 'block' : 'none' }}>
                                <CardContent
                                    style={{ textAlign: 'left' }}
                                >
                                    <Typography variant="h5" component="div">
                                        Loading
                                    </Typography>
                                    <Box style={{ width: '100%' }}>
                                        <LinearProgress />
                                    </Box>
                                </CardContent>
                            </Card>
                            <div style={{ display: isLoading ? 'none' : 'block' }}>
                                <Card className={styles.card}>
                                    <CardContent
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Typography gutterBottom variant="h5" component="div">
                                            Landscape
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <ComputerIcon />
                                                <span style={{ paddingLeft: "4px" }}>{props.gateways?.length} {props.gateways?.length === 1 ? 'Gateway' : 'Gateways'}</span>
                                            </div>
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <DomainIcon />
                                                <span style={{ paddingLeft: "4px" }}>{props.data.customDomain?.length} {props.data.customDomain?.length === 1 ? 'Custom domain' : 'Custom domains'}</span>
                                            </div>
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <CodeOutlinedIcon />
                                                <span style={{ paddingLeft: "4px" }}>{props.data.api?.length} {props.data.api?.length === 1 ? 'API' : 'APIs'}</span>
                                            </div>
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <EqualizerOutlinedIcon />
                                                <span style={{ paddingLeft: "4px" }}>{props.data.center?.charts?.length} {props.data.center?.charts?.length === 1 ? 'Center chart' : 'Center charts'}</span>
                                            </div>
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                    <Button size="small" color="primary" onClick={() => props.showLandscapeDetails()}>
                                        Details
                                    </Button>
                                </CardActions>
                                </Card>
                            </div>
                        </Content>
                    </Col>
                    <Col span={20}>
                        <Map data={props.data} gateways={props.gateways} showGateway={props.showGateway}></Map>
                    </Col>
                </Row>

            </Layout>
        </>
    );
}

export default Overview;