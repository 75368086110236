import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { useState } from "react";
import CreateResourceDialog from "../../CreateResourceDialog/CreateResourceDialog";
import CreateAPIResourceDialog from '../../CreateResourceDialog/CreateAPIResourceDialog';
import { apolloClient } from "../../../apolloClient";
import {
    gql,
    useMutation,
} from "@apollo/client";

interface Props {
    currentView: any,
    refreshTable: any
}

export default function ToolbarCreateButton(props: Props) {

    const [openDialog, setOpenDialog] = useState(false);

    // fetching all relevant api fields so that graphql caches the response
    const CREATE_API = gql`
    mutation CreateCoreApigwBtpSapComV1alpha1NamespacedAPI($namespace: String!, $comSapBtpApigwCoreV1alpha1APIInput: ComSapBtpApigwCoreV1alpha1APIInput!) {
        createCoreApigwBtpSapComV1alpha1NamespacedAPI(namespace: $namespace, comSapBtpApigwCoreV1alpha1APIInput: $comSapBtpApigwCoreV1alpha1APIInput) {
            metadata {
                name
                namespace
            }
            spec {
                source {
                    domain
                    hostPrefix
                }
                target {
                    host
                    port
                }
                gatewaySelector
            }
            status {
                conditions {
                    message
                    reason
                    status
                }
            }
       }
    }
    `
    const [createCoreApigwBtpSapComV1alpha1NamespacedAPI] = useMutation(CREATE_API, {
        client: apolloClient,
        refetchQueries: [
            'FetchAllResources',
        ],
    });

    // TODO: if api fails to publish, we'll keep polling forever
    function pollUntilAdded(results: any[], variables: any) {
        const handle = setInterval(async () => {
            const apis: any[] = results[0]?.data?.comSapBtpApigwCoreV1alpha1APIList?.items;
            const foundAPI = apis.find((value) => {
                return value.metadata.namespace === variables.namespace && value.metadata.name === variables.name;
            });
            results = await apolloClient.refetchQueries({
                include: ['FetchAllResources'],
                
            });
            props.refreshTable();
            if (foundAPI?.status?.conditions[0]?.status === 'True') {
                clearInterval(handle);
            } else if (foundAPI?.status?.conditions[0]?.status === 'False' && foundAPI?.status?.conditions[0]?.reason !== 'PublishInProgress') {
                console.error(`${foundAPI?.metadata.name}: ${foundAPI?.status?.conditions[0]?.reason}${foundAPI?.status?.conditions[0]?.message}`);
                clearInterval(handle);
            }
        }, 500);
    }

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    function handleOnCreate() {
        // TODO check connectivity to cluster and get details?
        console.log(`Create button was clicked.`);
        handleDialogOpen();
    }

    const handleCreateDialogConfirm = async (createFormData: any) => {
        const variables = {
            namespace: createFormData.namespace,
            comSapBtpApigwCoreV1alpha1APIInput: {
                apiVersion: "core.apigw.btp.sap.com/v1alpha1",
                kind: "API",
                metadata: {
                    name: createFormData.name,
                    namespace: createFormData.namespace,
                },
                spec: {
                    target: {
                        host: createFormData['target-host'],
                        port: createFormData['target-port'],
                    },
                    source: {
                        domain: createFormData['source-domain'],
                        hostPrefix: createFormData['source-prefix'],
                    },
                    gatewaySelector: createFormData['gateway-selectors'],
                }
            }
        };
        try {
            await createCoreApigwBtpSapComV1alpha1NamespacedAPI({ variables});
            // refetch until api is actualy deleted
            let results: any[] = await apolloClient.refetchQueries({
                include: ['FetchAllResources'],
            });
            props.refreshTable();
            pollUntilAdded(results, {name: createFormData.name, namespace: createFormData.namespace});
        } catch(e: any) {
            // TODO: Should get original reason for failure from K8s.  
            console.error(e.message);
        }
        console.log(`Confirm create button was clicked with the following data: ${JSON.stringify(createFormData)}`);
    };

    return (
        <>
            <Button
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleOnCreate}
            > Create
            </Button>
            {props.currentView.id === "api" ? <CreateAPIResourceDialog open={openDialog} resource={props.currentView.name} fields={props.currentView.configurableProps} handleClose={handleDialogClose}
                handleConfirm={handleCreateDialogConfirm}></CreateAPIResourceDialog> :
                <CreateResourceDialog open={openDialog} resource={props.currentView.name} fields={props.currentView.configurableProps} handleClose={handleDialogClose}
                    handleConfirm={handleCreateDialogConfirm} />}

        </>

    )
}