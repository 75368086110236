import { useReducer } from 'react';
import { Select, Modal, Form, Input } from 'antd';
import { APIRecordFields } from '../ResourceTable/APIsResourceTable';
import _ from "lodash";
import { getColumnTitleByColName } from '../../utils';

const { Option } = Select;

type Props = {
    open: boolean;
    resource: string
    fields: string[];
    handleClose: () => void;
    handleConfirm: (createFormData: any) => void;
};

const formReducer = (state: any, event: any) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

export default function CreateAPIResourceDialog(props: Props) {

    const [formData, setFormData] = useReducer(formReducer, {});

    const handleClose = () => {
        props.handleClose();
    }
    const handleConfirm = (event: any) => {
        event.preventDefault();
        props.handleConfirm(formData);
        props.handleClose();
    }

    const handleChange = (event: any) => {
        const target = event.target;
        const name = target.name;
        let value = target.value;
        if (target.type === 'number') {
            value = parseInt(value);
        }
        setFormData({
            name,
            value,
        });
    }

    const handleSelectChange = (value: any, allSelectedOptions: any) => {
        console.log(`selected ${value}`);
      
        // e.g.: "gateway-selectors": {"EU": true, "US": true}
        const gwSelectors: any = {};
        // let keyFromOption: string = "";
        const regionKeyword = "region";
        const publicKeyword = "public";
        
        // TODO: Need to fetch the field name dynamycally!
        const gwSelectorsKey = "gateway-selectors";

        // TODO: HACK for the DEMO!!!!! (EU and US were selected so public = "true")
        
        if (allSelectedOptions?.length === 2 || _.isEmpty(allSelectedOptions)) {
            // if (_.isEmpty(keyFromOption)) {                
            //     keyFromOption = allSelectedOptions[0].key;
            // }
            gwSelectors[publicKeyword] = "true";
        }
        else {
            _.forEach(allSelectedOptions, (selectedOption: any) => {
                // Take once the key of this field from the options
                // if (_.isEmpty(keyFromOption)) {                
                //     keyFromOption = selectedOption.key;
                // }
                gwSelectors[regionKeyword] = selectedOption.value;
            });
        }

         setFormData({
            name: gwSelectorsKey,
            value: gwSelectors,
        });
    }

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} is required.',
        types: {
            // eslint-disable-next-line no-template-curly-in-string
            email: '${label} is not a valid email.',
            // eslint-disable-next-line no-template-curly-in-string
            number: '${label} is not a valid number.',
        },
        number: {
            // eslint-disable-next-line no-template-curly-in-string
            range: '${label} must be between ${min} and ${max}',
        },
    };

    return (
        <div>
            <Modal width={600} title={`Create API`} visible={props.open} okText={'Confirm'} onOk={handleConfirm} onCancel={handleClose}>
                <Form {...layout} name="nest-messages" onFinish={handleConfirm} validateMessages={validateMessages}>
                    <Form.Item
                        name={[APIRecordFields.name, APIRecordFields.name]}
                        label={getColumnTitleByColName(APIRecordFields.name)}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input name={APIRecordFields.name} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        name={[APIRecordFields.namespace, APIRecordFields.namespace]}
                        label={getColumnTitleByColName(APIRecordFields.namespace)}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input name={APIRecordFields.namespace} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        name={[APIRecordFields.sourcePrefix, APIRecordFields.sourcePrefix]}
                        label={getColumnTitleByColName(APIRecordFields.sourcePrefix)}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input name={APIRecordFields.sourcePrefix} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        name={[APIRecordFields.sourceDomain, APIRecordFields.sourceDomain]}
                        label="Custom Domain"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input name={APIRecordFields.sourceDomain} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        name={[APIRecordFields.targetHost, APIRecordFields.targetHost]}
                        label={getColumnTitleByColName(APIRecordFields.targetHost)}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input name={APIRecordFields.targetHost} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item
                        name={[APIRecordFields.targetPort, APIRecordFields.targetPort]}
                        label={getColumnTitleByColName(APIRecordFields.targetPort)}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type='number' name={APIRecordFields.targetPort} onChange={handleChange}/>
                    </Form.Item>
                    <Form.Item name={[APIRecordFields.gatewaySelectors, APIRecordFields.gatewaySelectors]} 
                                label={getColumnTitleByColName(APIRecordFields.gatewaySelectors)}>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select gateways from the list"
                            defaultValue={[]}
                            onChange={handleSelectChange}
                        >
                            {/* Hardcoded list of possible gateways for API creation */}
                            <Option key="EU" value="EU">EU</Option>
                            <Option key="US" value="US">US</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}