
import { Typography, Space, Popover, Tooltip } from "antd";
import _ from "lodash";
import { Header } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useState } from "react";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import * as auth from '../services/auth.service';

const { Title } = Typography;

export default function CentralGWTitleBar(props: any) {
    const [showPopover, setShowPopover] = useState(false);

    function switchContext() {
        props.switchContext();
        setShowPopover(false);
    }

    useEffect(() => {
    }, [props]);

    const text = <Title level={5}>User</Title>;
    const content = (
        <div>
            {auth.getUserName() &&
                <p>{auth.getUserName()}</p>
            }
            <div style={{ display: 'flex' }}>
                <Button variant="outlined" startIcon={<SwapHorizOutlinedIcon />} style={{ marginLeft: 'auto', marginRight: '4px' }} onClick={() => switchContext()}>Change landscape</Button>

                <Button variant="outlined" style={{ marginLeft: 'auto' }} onClick={() => props.signOut()}>Logout</Button>
            </div>
        </div>
    );

    function changePopoverVisibility(val: boolean) {
        setShowPopover(val);
    }

    return (
        // TODO move inline styles
        <Header style={{ color: 'white', backgroundColor: "#3f51b5", padding: "0 1rem 0 1rem" }}>
            <div style={{ display: 'flex' }}>
                <Space>
                    <Title level={3} style={{ marginTop: "auto", marginBottom: "auto", color: "#ffffff" }}>Central Gateway</Title>
                    <Title level={4} style={{ marginTop: "auto", marginBottom: "auto", color: "#bfbfbf" }}>(Internal Development Tool)</Title>
                </Space>
                <div style={{ marginLeft: 'auto' }}>
                    {_.map(props.toolbarButtons, (toolbarButton) => toolbarButton)}
                    <Popover placement="bottomRight" title={text} content={content} trigger="click" onVisibleChange={changePopoverVisibility} visible={showPopover}>
                        <Tooltip placement="bottomRight" trigger={['hover', 'click']} title={auth.getUserName()}>
                            <IconButton size="small" style={{ color: 'black', backgroundColor: 'white' }} aria-label="upload picture" component="span">
                                <PersonOutlineIcon />
                            </IconButton>
                        </Tooltip>
                    </Popover>
                </div>
            </div>
        </Header>
    )
}