import _ from "lodash";
import {
    Table,
    TableColumnType,
    TablePaginationConfig,
    Descriptions,
    Layout,
    Empty,
    Card,
    Typography,
} from 'antd';
import {
    InfoCircleOutlined
} from '@ant-design/icons';
import { TableRowSelection, FilterDropdownProps, FilterValue, SorterResult, TableCurrentDataSource, ColumnFilterItem } from 'antd/lib/table/interface';
import { renderList } from './columnRenderers/renderList';
import { renderLink } from './columnRenderers/renderLink';
import { renderStatus } from './columnRenderers/renderStatus';
import { renderPublishedStatus } from './columnRenderers/renderPublishedStatus';
import 'antd/dist/antd.css';
import './APIsResourceTable.scss';
import { getBasicColumnDefinition, getColumnNamesByRows, getRowsDataPerColumn } from "./ResourceTable"
import { APIS, getColumnTitleByColName, splitStringByCamelCase } from '../../utils';
import CustomFilterMenu from './CustomFilterMenu';
import Loading from "../../pages/Loading/Loading";

const { Content } = Layout;
const { Meta } = Card;

export enum APIRecordFields {
    name = "name",
    namespace = "namespace",
    source = "source",
    sourcePrefix = "source-prefix",
    sourceDomain = "source-domain",
    targetHost = "target-host",
    targetPort = "target-port",
    gatewaySelectors = "gateway-selectors",
    envSelectors = "environment-selectors",
    publishedStatus = "published-status",
    publishedReason = "published-reason",
    publishedMessage = "published-message",
    dnsStatus = "dns-status",
    dnsName = "dns-name",
    dnsProvider = "dns-provider",
    certStatus = "certificate-status",
    certIssuer = "certificate-issuer",
    gateways = "gateways",
    lob = "lob",
}

/**
 * Row Record fields names as they are recieved from server
 */
interface RowRecord {
    name: string;
    namespace: string;
    "source-prefix": string;
    "source-domain": string;
    "target-host": string;
    "target-port": number;
    "gateway-selectors": { [key: string]: string; };
    "environment-selectors": [],
    "published-status": string;
    "published-reason": string;
    "published-message": string;
    "dns-status": boolean;
    "dns-name": string;
    "dns-provider": string;
    "certificate-status": boolean;
    "certificate-issuer": string;
    gateways: Array<string>;
}

interface APIsResourceTableProps {
    tableState: any;
    dataSource: any;
    onChange: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void;
    pagination: false | TablePaginationConfig;
    rowSelection: TableRowSelection<any>;
    rowKey: string;
}

export default function APIsResourceTable(props: APIsResourceTableProps) {
    // Declare columns explicitly and not derive from the primary columns since there might be fields we don't want to display at all.
    // TODO remove publishedStatus? looks redundant to show both status (publishedStatus, publishedReason)
    const primaryColumnNames: string[] = [
        APIRecordFields.sourceDomain,
        APIRecordFields.source,
        APIRecordFields.targetHost,
        APIRecordFields.namespace,
        APIRecordFields.publishedStatus,
        APIRecordFields.publishedReason,
        APIRecordFields.gateways
    ];

    const detailedColumnNames: string[] = [
        APIRecordFields.name,
        APIRecordFields.namespace,
        APIRecordFields.sourcePrefix,
        APIRecordFields.gatewaySelectors,
        APIRecordFields.envSelectors,
        APIRecordFields.publishedStatus,
        APIRecordFields.publishedMessage,
        APIRecordFields.dnsStatus,
        APIRecordFields.dnsName,
        APIRecordFields.dnsProvider,
        APIRecordFields.certStatus,
        APIRecordFields.certIssuer,
        APIRecordFields.lob
    ];

    /**
     * Takes ALL columns and returns a subset that matches the will be shown in table
     */
    const getPrimaryColumns = (columns: TableColumnType<any>[]): TableColumnType<any>[] => {
        return columns?.filter((column) => primaryColumnNames.includes(column.key as string));
    }

    /**
     * Returns specific columns definitions according to list and column names
     * 
     * See code example for column options: https://github.com/mui-org/material-ui-x/blob/14a2b22b371579778bfd06539b8807dc0314b2e1/packages/grid/x-grid-data-generator/src/commodities.columns.tsx#L53
     * 
     * @param listName 
     * @param columnName 
     * @returns 
     */
    function getColumnDefinition(listName: string, columnName: string, rowsDataPerColumn: any, tableState: any) {
        // General column definition
        const columnDefinition: TableColumnType<any> = getBasicColumnDefinition(columnName, rowsDataPerColumn, tableState);

        if (columnName === APIRecordFields.source) {
            columnDefinition.ellipsis = false;
            columnDefinition.render = renderLink;
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }
        else if (columnName === APIRecordFields.sourceDomain) {
            columnDefinition.title = "Custom Domain";
            columnDefinition.ellipsis = false;
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }
        else if (columnName === APIRecordFields.gateways) {
            columnDefinition.render = renderList;
            columnDefinition.ellipsis = false;
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }
        // TODO remove? looks redundant to show both status (publishedStatus, publishedReason)
        else if (columnName === APIRecordFields.publishedStatus) {
            columnDefinition.render = renderStatus;
            columnDefinition.filters = _.map(rowsDataPerColumn && rowsDataPerColumn[columnName], (columnPossibleValue: string) => {
                return { text: columnPossibleValue?.toString() === "True" ? "Ready" : "Not Ready", value: columnPossibleValue?.toString() }
            }) || [] as ColumnFilterItem[];
        }
        else if (columnName === APIRecordFields.publishedReason) {
            columnDefinition.title = "Status";
            columnDefinition.render = renderPublishedStatus;
            columnDefinition.filters = _.map(rowsDataPerColumn && rowsDataPerColumn[columnName], (columnPossibleValue: string) => {
                return { text: splitStringByCamelCase(columnPossibleValue?.toString()), value: columnPossibleValue?.toString() }
            }) || [] as ColumnFilterItem[];
        }
        else if (columnName === APIRecordFields.lob) {
            columnDefinition.title = "LOB";
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }

        return columnDefinition;
    }

    function getColsAndRows(listName: string, listRows: RowRecord[], tableState: any) {

        let columns: TableColumnType<any>[] = [];
        let rows: any = [];

        if (!_.isEmpty(listRows)) {
            rows = _.map(listRows, (row: any) => {
                _.forEach(Object.keys(row), (columnName: string) => {
                    if (_.isPlainObject(row[columnName])) {
                        // Need to convert the object data
                        // TODO need to make sure what are the possible values and how to present them better
                        row[columnName] = _.map(row[columnName], (value, key) => `${key}=${JSON.stringify(value)}`).join(",");
                    }
                });
                return {
                    key: `${row.namespace}-${row.name}`,
                    source: `${row[APIRecordFields.sourcePrefix]}.${row[APIRecordFields.sourceDomain]}`,
                    ...row
                }
            }) || [];

            const rowsDataPerColumn = getRowsDataPerColumn(rows);
            const columnNames = getColumnNamesByRows(rows);
            columns = _.map(columnNames, (columnName) => {
                return getColumnDefinition(listName, columnName, rowsDataPerColumn, tableState);
            }) || [];
        }

        return { columns, rows };
    }

    let data: { columns: TableColumnType<any>[], rows: any } = getColsAndRows(APIS, props.dataSource, props.tableState);
    const primaryColumns = getPrimaryColumns(data.columns);

    function DetailedInfo(props: any) {
        return (
            <Layout>
                <Content>
                    <Card>
                        <Meta
                            avatar={<InfoCircleOutlined style={{ color: "black", fontSize: "150%" }} />}
                            title={<Typography.Title level={5}>Detailed information</Typography.Title>}
                        />
                        <Descriptions size="small" column={3}>
                            {
                                detailedColumnNames.map((columnName) => {
                                    const value: any = props.data[columnName];
                                    if (value) {
                                        return <Descriptions.Item
                                            label={getColumnTitleByColName(columnName)}
                                            className="description-item"
                                            key={columnName}
                                        >
                                            {value?.toString()}
                                        </Descriptions.Item>
                                    }
                                })
                            }
                        </Descriptions>
                    </Card>
                </Content>
            </Layout>
        );
    }

    return (<Table
        size={"middle"}
        sticky={true}
        scroll={{ x: "100%" }}
        columns={primaryColumns}
        dataSource={data.rows}
        onChange={props.onChange}
        pagination={props.pagination}
        rowSelection={props.rowSelection}
        expandable={{ expandedRowRender: record => <DetailedInfo data={record} /> }}
        rowKey={record => `${record.namespace}-${record.name}`}
        tableLayout={"auto"}
        locale={{
            emptyText: data.rows.length !== 0 ? <Empty /> : <Loading />
        }}
    />);
}