import { useEffect, useRef } from 'react';

export const GATEWAYS = "Gateways";
export const CHARTS = "Charts";
export const APIS = "APIs";
export const CUSTOM_DOMAINS = "Custom Domains";
export const ON_BOARDING = "On Boarding";

// This list describe real k8s resources!
export const apiID = "api";
export const customDomainID = "customDomain";
export const gwID = "gateway";
export const ResourcesList = [apiID, customDomainID, gwID];
export const ResourceNameMapping: any = {
    "api": APIS,
    "customDomain": CUSTOM_DOMAINS,
    "gateway": GATEWAYS,
    "charts": CHARTS,
}

export const useInterval = (callback: any, delay: number) => {

    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);


    useEffect(() => {
        function tick() {
            // @ts-ignore
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

/**
 * Gets column name with "-" seperator and returns readable column name
 * e.g. target-host --> Target Host
 * @param columnName 
 * @returns 
 */
export const getColumnTitleByColName = (columnName: string): string => {
    return columnName.split("-").map((w: string) => w.charAt(0).toUpperCase() + w.slice(1)).join(" ");
}

/**
 * e.g. PublishedSuccessfully -> Published Successfully
 * @param value  
 */
export const splitStringByCamelCase = (value: string) : string => {
    let wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
    return value?.match(wordRe)?.join(" ") || "";
}