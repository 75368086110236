import { Layout } from 'antd';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ComputerIcon from '@material-ui/icons/Computer';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import Typography from '@material-ui/core/Typography';
import 'antd/dist/antd.css';
import { useState, useEffect, useRef } from 'react';
import styles from "./Map.module.scss";
import { IGateway, ViewType } from '../ResourceManagement/ResourceManagement';

const { Content } = Layout;

const locations = [
    { region: undefined, x: 300, y: 1000 },
    { region: 'EU', x: 2000, y: 300 },
];

const mapWidth: number = 2980;
const mapHeight: number = 1456;
const mapWidth2HeightRatio: number = mapWidth / mapHeight;

interface MapProps {
    data: any;
    gateways: IGateway[];
    showGateway: any;
}

interface CardData {
    visibility: VisibilityState,
    left: string,
    top: string,
    mainText: string,
    gateway: any,
}

interface CircleData {
    cardData: CardData,
    circle: Path2D,
}

function Map(props: MapProps) {
    let _cardData: CardData = { visibility: 'hidden', left: '0px', top: '0px', mainText: 'Center', gateway: undefined, };
    const [cardData, setCardData] = useState<CardData>(_cardData);
    const [mapCursor, setMapCursor] = useState<string>('default');

    const canvasRef = useRef<any>(null);
    const cardRef = useRef<any>(null);

    useEffect(() => {
        // for smooth rendering of map
        function fixDpi() {
            let style = {
                height() {
                    return +getComputedStyle(canvas).getPropertyValue('height').slice(0, -2);
                },
                width() {
                    return +getComputedStyle(canvas).getPropertyValue('width').slice(0, -2);
                }
            }

            let canvasWidth: number = style.width() * dpi;
            let canvasHeight: number = style.height() * dpi;

            canvas.setAttribute('width', canvasWidth);
            canvas.setAttribute('height', canvasHeight);

            let height: number = canvas.height;
            let width: number = canvas.width;
            if (canvas.width / canvas.height < mapWidth2HeightRatio) {
                height = width / mapWidth2HeightRatio;
            } else {
                width = height * mapWidth2HeightRatio;
            }
            actualToNaturalMapRatio = width / mapWidth;
        }

        let dpi = window.devicePixelRatio;
        let actualToNaturalMapRatio: number = 1;

        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d');

        const edgeImg = new Image();
        edgeImg.src = '/static/images/edge.png';

        const mapImg = new Image();
        mapImg.src = '/static/images/map.png';

        let circlesData: CircleData[] = [];

        canvas.addEventListener('mousemove', function (event: any) {
            let outsideAllCircles: boolean = true;
            for (const circleData of circlesData) {
                const inCircle: boolean = context.isPointInPath(circleData.circle, event.offsetX * dpi, event.offsetY * dpi);
                if (inCircle) {
                    outsideAllCircles = false;
                    break;
                }
            }
            if (outsideAllCircles) {
                setMapCursor('default');
            } else {
                setMapCursor('pointer');
            }
        });

        canvas.addEventListener('click', function (event: any) {
            let outsideAllCircles: boolean = true;
            for (const circleData of circlesData) {
                const inCircle: boolean = context.isPointInPath(circleData.circle, event.offsetX * dpi, event.offsetY * dpi);
                circleData.cardData = {
                    left: circleData.cardData.left,
                    top: circleData.cardData.top,
                    visibility: inCircle ? 'visible' : 'hidden',
                    mainText: circleData.cardData.mainText,
                    gateway: circleData.cardData.gateway,
                }
                setCardData(circleData.cardData);
                if (inCircle) {
                    outsideAllCircles = false;
                    break;
                }
            }
            if (outsideAllCircles) {
                const cardData: CardData = { visibility: 'hidden', left: '0px', top: '0xp', mainText: 'Center', gateway: undefined, };
                setCardData(cardData);
            }
        });

        let widthRatio = 1;

        requestAnimationFrame(draw);

        function draw() {
            fixDpi();
            context.clearRect(0, 0, canvas.width, canvas.height);
            if (mapImg.complete) {
                context.drawImage(mapImg, 0, 0, actualToNaturalMapRatio * mapWidth, actualToNaturalMapRatio * mapHeight);
                widthRatio = mapImg.naturalWidth / context.canvas.width;
            };

            circlesData = [];

            if (props.gateways) {
                for (const gateway of props.gateways) {
                    const region = gateway.labels?.region;
                    let location = locations.find((value) => {
                        return value.region === region;
                    }) || { region: undefined, x: 0, y: 0 };
                    if (edgeImg.complete) {
                        context.drawImage(
                            edgeImg,
                            location.x * actualToNaturalMapRatio - edgeImg.naturalWidth * actualToNaturalMapRatio / 2,
                            location.y * actualToNaturalMapRatio - edgeImg.naturalHeight * actualToNaturalMapRatio / 2,
                            edgeImg.naturalWidth * actualToNaturalMapRatio,
                            edgeImg.naturalHeight * actualToNaturalMapRatio,
                        );
                        const edgeCircle = new Path2D();
                        edgeCircle.arc(
                            location.x * actualToNaturalMapRatio,
                            location.y * actualToNaturalMapRatio,
                            edgeImg.width * actualToNaturalMapRatio / 2,
                            0,
                            2 * Math.PI
                        );
                        context.fillStyle = "rgba(255, 255, 255, 0)";
                        context.fill(edgeCircle);
                        let title = `${gateway.name} (${region})`;
                        if (!region) {
                            title = gateway.name;
                        }
                        context.font = '36px arial';
                        context.textAlign = 'center';
                        context.textBaseline = 'top';
                        context.fillStyle = "black";
                        context.fillText(title, location.x * actualToNaturalMapRatio, location.y * actualToNaturalMapRatio + edgeImg.height / 2 + 6);
                        const cardData: CardData = {
                            left: `${location.x / widthRatio / dpi}px`,
                            top: `${location.y / widthRatio / dpi}px`,
                            visibility: 'hidden',
                            mainText: title,
                            gateway,
                        };
                        circlesData.push({ cardData, circle: edgeCircle });
                    };
                }
            }
            requestAnimationFrame(draw);
        }
    }, [props.gateways]);

    return (
        <>
            <Layout style={{ width: "100%", height: "100%" }}>
                <Content className={styles.wrapper}>
                    <canvas id="mapCanvas" ref={canvasRef} className={styles.mapCanvas} style={{ cursor: mapCursor }}>
                    </canvas>
                    <Card className={styles.gatewayCard} ref={cardRef} style={{ left: cardData.left, top: cardData.top, visibility: cardData.visibility }}>
                        <CardContent
                            style={{ textAlign: 'left' }}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <ComputerIcon />
                                <Typography variant="h5" component="div">
                                    Gateway
                                </Typography>
                                <Typography variant="subtitle1" component="div">
                                    {cardData.gateway?.name}
                                </Typography>
                                {cardData.gateway?.labels?.region &&
                                    <Typography gutterBottom variant="subtitle2" component="div">
                                        {`(${cardData.gateway?.labels?.region})`}
                                    </Typography>
                                }
                            </div>
                            {cardData.gateway?.edge?.charts &&
                                <Typography variant="subtitle2">
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <EqualizerOutlinedIcon />
                                        <span style={{ paddingLeft: "4px" }}>{`${cardData.gateway?.edge?.charts.length} Charts`}</span>
                                    </div>
                                </Typography>
                            }
                            {cardData.gateway?.edge?.filters &&
                                <Typography variant="subtitle2">
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <FilterListOutlinedIcon />
                                        <span style={{ paddingLeft: "4px" }}>{`${cardData.gateway?.edge?.filters.length} Filters`}</span>
                                    </div>
                                </Typography>
                            }
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => props.showGateway(cardData.gateway?.name, ViewType.VISUAL)}>
                                More
                            </Button>
                        </CardActions>
                    </Card>
                </Content>
            </Layout>
        </>
    );
}

export default Map;