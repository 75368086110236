import { Typography, Table, Divider, Row, Col, Tooltip } from 'antd';
import { useContext } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styles from "./Center.module.scss";
import CGAppContext from "../../AppContext";

const { Title, Text } = Typography;

type Props = {
    center?: any;
}

const chartColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

const chartDeploymentColumns: any = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        render: (text: string, record: any) => (
            record.message && <Tooltip placement="topLeft" title={record.message}>
                <InfoOutlinedIcon />
            </Tooltip>
        ),
    }
];

export default function Center(props: Props) {
    const appContext = useContext(CGAppContext);

    return (
        <div className={styles.Wrapper}>
            <Title level={2} style={{
                marginBottom: '0px',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    justifyContent: 'center',
                }}>
                    Landscape
                </div>
            </Title>

            <Divider orientation="left"></Divider>
            <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Kubernetes Context</Title>
                    <Text>{appContext.cluster.context}</Text>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Name</Title>
                    <Text>{props.center?.name}</Text>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Namespace</Title>
                    <Text>{props.center?.namespace}</Text>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Title level={5}>Creation Time</Title>
                    <Text>{new Date(props.center?.creationTimestamp).toString()}</Text>
                </Col>
            </Row>

            <Divider orientation="left"></Divider>

            <Title level={5}>
                Chart Deployment Status
            </Title>
            <Table size="small" columns={chartDeploymentColumns} dataSource={props.center?.conditions?.map((condition: any) => {
                return {
                    key: `${condition.lastTransitionTime}-${condition.type}`,
                    time: `${new Date(condition.lastTransitionTime)}`,
                    reason: condition.reason,
                    type: condition.type,
                    message: condition.message,
                }
            })} />

            <Title level={5}>
                Charts
            </Title>
            <Table size="small" columns={chartColumns} dataSource={props.center?.charts?.map((chart: any) => {
                return {
                    key: `${chart.namespace}-${chart.name}`,
                    name: chart.name,
                    version: chart.version,
                    status: chart['release-status-description'],
                }
            })} />
        </div>
    );
}
