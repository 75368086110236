import React from 'react';

const CGAppContext = React.createContext<{
    kubeconfig: string,
    namespace: string,
    cluster: {context?: string, lob?: string, group?: string, namespace?: string,},
    userEmail: string
}>({
    kubeconfig: "",
    namespace: "",
    cluster: {},
    userEmail: '',
});

export default CGAppContext;