import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import _ from "lodash";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apolloClient } from "../../../apolloClient";
import {
    gql,
    useMutation,
} from "@apollo/client";

interface Props {
    selectedRow: any,
    currentView: any,
    refreshTable: any
}

export default function ToolbarEditButton(props: Props) {

    const DELETE_API = gql`
    mutation DeleteCoreApigwBtpSapComV1alpha1NamespacedAPI($namespace: String!, $name: String!) {
        deleteCoreApigwBtpSapComV1alpha1NamespacedAPI(namespace: $namespace, name: $name) {
            # status
            reason
            message
        }
    }
    `

    const [deleteCoreApigwBtpSapComV1alpha1NamespacedAPI] = useMutation(DELETE_API, {
        client: apolloClient,
    });

    function pollUntilDeleted(results: any[], variables: any) {
        const handle = setInterval(async () => {
            const apis: any[] = results[0]?.data?.comSapBtpApigwCoreV1alpha1APIList?.items;
            const foundAPI = apis?.find((value) => {
                return value.metadata.namespace === variables.namespace && value.metadata.name === variables.name;
            });
            results = await apolloClient.refetchQueries({
                include: ['FetchAllResources'],
            });
            props.refreshTable();
            if (!foundAPI) {
                clearInterval(handle);
            }
        }, 500);
    }

    async function handleOnDelete() {
        console.log(`Delete button was clicked with ${props.selectedRow.key}.`);

        var [namespace, name] = props.selectedRow.key.split('.');

        const variables = {
            namespace,
            name,
        };
        
        await deleteCoreApigwBtpSapComV1alpha1NamespacedAPI({ variables });

        // refetch until api is actualy deleted
        let results: any[] = await apolloClient.refetchQueries({
            include: ['FetchAllResources'],
        });
        props.refreshTable();
        pollUntilDeleted(results, variables);
    }

    function confirm() {
        Modal.confirm({
            title: 'Confirm Deletion',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete?',
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: handleOnDelete
        });
    }

    return (
        <Button
            disabled={_.isEmpty(props.selectedRow)}
            color="primary"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={confirm}
        >Delete
        </Button>
    )
}