import React, {useReducer, useState, useContext} from 'react';
import {Button, TextField} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {onBoarding} from "../../services/general-http.service";
import _ from "lodash";
import CentralGWTitleBar from '../../components/CentralGWTitleBar';
import {Card} from 'antd';
import {makeStyles} from "@material-ui/core/styles";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import Typography from "@material-ui/core/Typography";
import AppContext from "../../AppContext";

const useStyles = makeStyles((theme) => ({
    img: {
        width: "8rem",
        marginBottom: "1.5rem",
    }
}));

const formReducer = (state: any, event: any) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

function FormMessage(props: any) {
    if (props.shouldMessageAppear) {
        return (
            props.isOnBoardSuccess ?
                <MuiAlert className={props.className} severity="success">
                    You have successfully on boarded! Please check your email for further information.
                </MuiAlert> :
                <MuiAlert className={props.className} severity="error">
                    There was an error while trying to on board.
                    <br></br>
                    {props.message}
                </MuiAlert>
        );
    }
    return null;
}

function OnBoarding(props: any) {
    const context = useContext(AppContext);
    const k8sContext = context.cluster.context;

    const [formData, setFormData] = useReducer(formReducer, {});
    const [isOnBoardSuccess, setOnBoardSuccess] = useState(true);
    const [shouldMessageAppear, setShouldMessageAppear] = useState(false);
    const [message, setMessage] = useState<string>('');

    const submitOnBoarding = async (formData: any) => {

        const body = {
            email: formData.email,
            subdomain: formData.subdomain,
            lob: formData.lob,
            context: k8sContext,
        };
        let onBoardResult;
        try {
            onBoardResult = await onBoarding(body);
            if (onBoardResult.status >= 200 && onBoardResult.status < 300) {
                setOnBoardSuccess(true);
                setShouldMessageAppear(true);
            } else {
                setOnBoardSuccess(false);
                setShouldMessageAppear(true);
                setMessage('');
            }
        } catch (error: any) {
            setMessage(error.response?.data?.message);
            setOnBoardSuccess(false);
            setShouldMessageAppear(true);
        }

    }

    const handleSubmit = async (event: any) => {
        event.preventDefault(); // prevents refreshing the page
        await submitOnBoarding(formData);
    }

    const handleChange = (event: any) => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const isDisabled = () => {
        return !(!_.isEmpty(formData.email) && !_.isEmpty(formData.subdomain) && !_.isEmpty(formData.lob));
    }
    const classes = useStyles();

    return (
        <div>
            <CentralGWTitleBar userEmail={props.userEmail} signOut={props.signOut} roles={props.roles}/>

            <div style={{display: "flex", justifyContent: "center"}}>
                <Card style={{textAlign: "center", marginTop: "12rem", width: "450px"}}>

                    <img className={classes.img} alt="ccs logo" src="/static/images/ccslogo.png"/>
                    <Typography component="h1" variant="h5">
                        Onboarding to Central Gateway
                    </Typography>
                    <div>

                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <TextField id="email-input"
                                       fullWidth
                                       label="Email address"
                                       name="email"
                                       required={true}
                                       onChange={handleChange}
                                       autoComplete="email"
                                       autoFocus
                                       margin="normal"
                                       type="email"
                                       variant="outlined"
                            />
                            <TextField id="lob-input"
                                       name="lob"
                                       label="LOB"
                                       variant="outlined"
                                       margin="normal"
                                       required={true}
                                       onChange={handleChange}
                                       fullWidth
                            />
                            <TextField variant="outlined"
                                       margin="normal"
                                       id="subdomain-input"
                                       fullWidth
                                       name="subdomain"
                                       label="Custom Domain"
                                       required={true}
                                       onChange={handleChange}
                            />
                            <Button style={{marginTop: "1rem", marginBottom: "1rem"}} color="primary"
                                    startIcon={<FlightTakeoffIcon/>}
                                    variant="contained"
                                    type="submit"
                                    disabled={isDisabled()}
                                    fullWidth
                            >On Board</Button>
                            <FormMessage shouldMessageAppear={shouldMessageAppear}
                                         isOnBoardSuccess={isOnBoardSuccess}
                                         message={message}/>
                        </form>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default OnBoarding;
