import { Button } from "@material-ui/core";
import { HomeProps } from "../App";
import { useContext } from "react";
import Kubeconfig from '../pages/Kubeconfig/Kubeconfig'
import ResourceManagement from "../pages/ResourceManagement/ResourceManagement";
import { Layout, } from "antd";
import CentralGWTitleBar from "../components/CentralGWTitleBar";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { useHistory } from 'react-router-dom';

export default function Home(props: HomeProps) {
    const history = useHistory();

    return (
        <Layout style={{ minHeight: '100%' }}>
            {!props.isAuthenticated && <p>Loading Central Gateway Admin ......................</p>}

            {props.isAuthenticated &&
                <CentralGWTitleBar userEmail={props.userEmail} signOut={props.signOut} switchContext={props.switchContext} toolbarButtons={props.isShowKubeconfigPage ? [] : [
                    <Button key={"onboarding"} startIcon={<FlightTakeoffIcon />} onClick={() => history.push("/onBoarding")}
                        style={{ color: "white", marginLeft: "auto" }}>
                        On Boarding
                    </Button>
                ]} />
            }

            {props.isAuthenticated &&
                props.isShowKubeconfigPage ? <Kubeconfig onAcceptedKubeconfig={props.onAcceptedKubeconfig} onSetKubeCluster={props.onSetKubeCluster} isAuthenticated={props.isAuthenticated} /> :
                props.isShowManagementPage ? <ResourceManagement userEmail={props.userEmail} signOut={props.signOut} /> : <div />
            }
        </Layout>);
}