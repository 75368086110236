import { useReducer } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from "@material-ui/core";

type Props = {
    open: boolean;
    resource: string
    fields: string[];
    handleClose: () => void;
    handleConfirm: (createFormData: any) => void;
};

const formReducer = (state: any, event: any) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

export default function CreateResourceDialog(props: Props) {

    const [formData, setFormData] = useReducer(formReducer, {});

    const handleClose = () => {
        props.handleClose();
    }
    const handleConfirm = (event: any) => {
        event.preventDefault();
        props.handleConfirm(formData);
    }

    const handleChange = (event: any) => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    return (
        <div>
            <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create {props.resource}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the following fields:
                    </DialogContentText>
                    {props.fields.map((field: string) => <TextField
                        // autoFocus
                        key={field}
                        margin="dense"
                        id={field}
                        name={field}
                        label={field}
                        type=""
                        fullWidth
                        onChange={handleChange}
                    />)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}